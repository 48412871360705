import { colors } from "@fonoa/design/tokens";
import { DefaultRawDatum, ResponsivePie } from "@nivo/pie";
import { useMemo } from "react";

import { NexusGroup, statusToDarkColor, statusToText } from "./nexus.types";

interface DonutSlice extends DefaultRawDatum {
  color: string;
}

interface NexusLiabilityProps {
  groups: NexusGroup[];
  loading: boolean;
}

const loadingData = [
  {
    id: "unknown",
    value: 100,
    color: colors.blueGray100,
  },
] as DonutSlice[];

function toChartData(groups: NexusGroup[]): DonutSlice[] {
  return groups.map((g) => ({
    id: statusToText(g.status),
    value: g.count,
    color: colors[statusToDarkColor(g.status)],
  }));
}

export function NexusLiabilityChart({ groups, loading }: NexusLiabilityProps) {
  const chartData = useMemo(() => (loading ? loadingData : toChartData(groups)), [loading, groups]);

  return (
    <ResponsivePie
      data={chartData}
      innerRadius={0.5}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      colors={(d) => d.data.color}
    />
  );
}

import { Button } from "@fonoa/ui-components/button";
import { TableColumn } from "@fonoa/ui-components/table";
import { Typography } from "@fonoa/ui-components/typography";
import { useRouter } from "next/router";
import { FormattedMessage, useIntl } from "react-intl";

import { routes } from "@/lib/routes";

import { NexusStatus, statusToDarkColor, statusToText } from "./nexus.types";

export interface AnyGroup {
  status?: NexusStatus;
  percentage: number;
  count: number;
}

export function useColumns() {
  const intl = useIntl();
  const router = useRouter();
  const columns: TableColumn<AnyGroup>[] = [
    {
      Header: intl.formatMessage({ defaultMessage: "Status", id: "tzMNF3" }),
      accessor: "status",
      disableSortBy: true,
      noWrap: true,
      Cell: ({ row }) => {
        if (!row.original.status) {
          return (
            <Typography component="p">
              <FormattedMessage defaultMessage="Total" id="MJ2jZQ" />
            </Typography>
          );
        }
        const color = statusToDarkColor(row.original.status);
        const text = statusToText(row.original.status);
        return (
          <div className="flex items-center ">
            <div className={`bg-${color} mr-2 h-2 w-2 rounded-full`} />
            <Typography component="p">{text}</Typography>
          </div>
        );
      },
    },
    {
      Header: intl.formatMessage({ defaultMessage: "%", id: "kZcqo0" }),
      accessor: "percentage",
      disableSortBy: true,
      noWrap: true,
      Cell: ({ row }) => {
        if (!row.original.status) return null;
        const percentage = (row.original.percentage * 100).toFixed(2);
        return row.original.status ? (
          <Typography component="p">{`${percentage}%`}</Typography>
        ) : null;
      },
    },
    {
      Header: intl.formatMessage({ defaultMessage: "# Jurisdictions", id: "c9eImN" }),
      accessor: "count",
      disableSortBy: true,
      noWrap: true,
      className: "text-right text-primaryBlue500",
      Cell: ({ row }) => {
        return (
          <Button variant="LINK" onClick={() => router.push(routes.taxCalculations)}>
            {row.original.count}
          </Button>
        );
      },
    },
  ];
  return columns;
}

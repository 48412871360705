import { locationCodeToName } from "@fonoa/location-code-to-name";
import { Table, TableColumn } from "@fonoa/ui-components/table";
import { Tag } from "@fonoa/ui-components/tag";
import { Typography } from "@fonoa/ui-components/typography";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { getRevenuePercent, getTransactionPercent } from "./Map";
import {
  isStatusInProgress,
  StateInfo,
  statusToDarkColor,
  statusToText,
  statusToTextColor,
} from "./nexus.types";

interface ThresholdListProps {
  isLoading: boolean;
  data: StateInfo[];
}

export function USThresholdList({ isLoading, data }: ThresholdListProps) {
  const intl = useIntl();
  const sortedStates = useMemo(
    () =>
      data
        .filter((s) => s.status !== "NO_ACTIVITY")
        .sort((a, b) =>
          locationCodeToName("us", a.fips).localeCompare(locationCodeToName("us", b.fips))
        ),
    [data]
  );

  return (
    <Table
      emptyMessage={
        <div className="flex items-center">
          <Typography>
            <FormattedMessage defaultMessage="Criteria return no results." id="6bO0ZH" />{" "}
            <span className="text-primaryBlue500">
              <FormattedMessage defaultMessage="Please try different criteria." id="CH5cGa" />
            </span>
          </Typography>
        </div>
      }
      loading={isLoading}
      className="w-full"
      columns={
        [
          {
            Header: (
              <div className="pl-4">
                <FormattedMessage defaultMessage="State" id="ku+mDU" />
              </div>
            ),
            accessor: "fips",
            align: Table.columnAlign.LEFT,
            Cell: ({ row }) => {
              return (
                <div className="py-2 pl-4"> {locationCodeToName("us", row.original.fips)} </div>
              );
            },
            clearPy: true,
            noWrap: true,
          },
          {
            Header: <FormattedMessage defaultMessage="Status" id="tzMNF3" />,
            accessor: "status",
            align: Table.columnAlign.LEFT,
            clearPy: true,
            noWrap: true,
            Cell: ({ row }) => {
              return (
                <Tag
                  color={statusToDarkColor(row.original.status) as unknown as any}
                  rounded={false}
                  size="xs"
                >
                  <span title={row.original.thresholdDescription}>
                    {statusToText(row.original.status)}
                  </span>
                </Tag>
              );
            },
          },
          {
            Header: <FormattedMessage defaultMessage="Transactions" id="/jJLYy" />,
            accessor: "transactions",
            noWrap: true,
            align: Table.columnAlign.RIGHT,
            clearPy: true,
            Cell: ({ row }) => {
              return (
                <div>
                  {row.original.transactionThreshold
                    ? intl.formatNumber(row.original.transactions ?? 0)
                    : "N/A"}
                </div>
              );
            },
          },
          {
            Header: (
              <div className="sr-only">
                <FormattedMessage defaultMessage="Transaction threshold" id="IJXf8c" />
              </div>
            ),
            accessor: "transactionThreshold",
            noWrap: true,
            align: Table.columnAlign.LEFT,
            clearPy: true,
            Cell: ({ row }) => {
              return (
                row.original.transactionThreshold &&
                isStatusInProgress(row.original.status) && (
                  <div className="pl-1">
                    <Typography
                      component="span"
                      variant="small"
                      color={`text-${statusToTextColor(row.original.transactionStatus)}`}
                    >
                      {`${getTransactionPercent(row.original)}`}
                    </Typography>
                  </div>
                )
              );
            },
          },
          {
            Header: <FormattedMessage defaultMessage="Revenue" id="8WKgF6" />,
            accessor: "revenue",
            align: Table.columnAlign.RIGHT,
            noWrap: true,
            clearPy: true,
            Cell: ({ row }) => {
              return (
                <div>
                  {row.original.revenueThreshold ? (
                    <>
                      <FormattedMessage defaultMessage="USD " id="Dg1bmq" />
                      {intl.formatNumber(row.original.revenue ?? 0)}
                    </>
                  ) : (
                    "N/A"
                  )}
                </div>
              );
            },
          },
          {
            Header: (
              <div className="sr-only">
                <FormattedMessage defaultMessage="Revenue threshold" id="z7ENPu" />
              </div>
            ),
            accessor: "revenueThreshold",
            align: Table.columnAlign.LEFT,
            noWrap: true,
            clearPy: true,
            Cell: ({ row }) => {
              return (
                <div>
                  {" "}
                  {row.original.revenueThreshold && isStatusInProgress(row.original.status) && (
                    <div className="pl-1">
                      <Typography
                        component="span"
                        variant="small"
                        color={`text-${statusToTextColor(row.original.transactionStatus)}`}
                      >
                        {`${getRevenuePercent(row.original)}`}
                      </Typography>
                    </div>
                  )}
                </div>
              );
            },
          },
        ] as TableColumn<StateInfo>[]
      }
      tightColumns={true}
      data={sortedStates}
    />
  );
}

import { ThemeColor } from "@fonoa/ui-components/types";

export type NexusStatus =
  | "NO_ACTIVITY"
  | "REGISTERED"
  | "TO_MONITOR"
  | "APPROACHING_THRESHOLD"
  | "THRESHOLD_EXCEEDED";

export interface StateInfo {
  fips: string;
  status: NexusStatus;
  thresholdDescription: string;
  revenue: number;
  revenueThreshold?: number;
  revenueStatus: NexusStatus;
  transactions: number;
  transactionThreshold?: number;
  transactionStatus: NexusStatus;
}

export function statusToLightColor(status: NexusStatus): ThemeColor {
  switch (status) {
    case "REGISTERED":
      return "green100";
    case "TO_MONITOR":
      return "yellow100";
    case "APPROACHING_THRESHOLD":
      return "tangerine100";
    case "THRESHOLD_EXCEEDED":
      return "red100";
    case "NO_ACTIVITY":
    default:
      return "blueGray100";
  }
}

export function statusToDarkColor(status: NexusStatus): ThemeColor {
  switch (status) {
    case "REGISTERED":
      return "green300";
    case "TO_MONITOR":
      return "yellow400";
    case "APPROACHING_THRESHOLD":
      return "tangerine400";
    case "THRESHOLD_EXCEEDED":
      return "red300";
    case "NO_ACTIVITY":
    default:
      return "blueGray300";
  }
}

export function statusToText(status: NexusStatus): string {
  switch (status) {
    case "NO_ACTIVITY":
      return "No Sales Activity";
    case "REGISTERED":
      return "Registered";
    case "TO_MONITOR":
      return "To Monitor";
    case "THRESHOLD_EXCEEDED":
      return "Threshold Exceeded";
    case "APPROACHING_THRESHOLD":
      return "Approaching Threshold";
    default:
      return "Unknown";
  }
}

export function statusToTextColor(status: NexusStatus): ThemeColor {
  switch (status) {
    case "REGISTERED":
      return "green700";
    case "TO_MONITOR":
      return "yellow800";
    case "APPROACHING_THRESHOLD":
      return "tangerine700";
    case "THRESHOLD_EXCEEDED":
      return "red700";
    case "NO_ACTIVITY":
    default:
      return "blueGray600";
  }
}

export function isStatusInProgress(status: NexusStatus): boolean {
  return ["TO_MONITOR", "APPROACHING_THRESHOLD", "THRESHOLD_EXCEEDED"].includes(status);
}

export interface NexusGroup {
  status: NexusStatus;
  count: number;
}

export interface ThresholdTracker<T extends string | Date> {
  _id?: unknown;
  tenant: string;
  country: string;
  region?: string;
  revenue: TrackedTotals<T>;
  count: TrackedTotals<T>;
  evaluatingFrom: T;
  evaluatingTo: T;
}

interface TrackedTotals<T extends string | Date> {
  total: number;
  months: MonthlyTotal<T>[];
}

interface MonthlyTotal<T extends string | Date> {
  total: number;
  month: T;
}

export class ThresholdRow {
  public country: string;
  public region?: string;
  public registered: boolean;
  public revenueThreshold: number;
  public revenue: number;
  public currency: string;

  get description(): string {
    if (!this.revenueThreshold) return "No threshold";

    return `in excess of ${this.revenueThreshold} revenue`;
  }

  get status(): NexusStatus {
    if (this.registered) return "REGISTERED";

    const fraction = this.revenue / (this.revenueThreshold ?? Number.POSITIVE_INFINITY);
    if (fraction > 1) return "THRESHOLD_EXCEEDED";
    if (fraction > 0.75) return "APPROACHING_THRESHOLD";
    if (fraction > 0) return "TO_MONITOR";
    return "NO_ACTIVITY";
  }

  get progressPercentage(): number {
    if (this.revenue === 0) return 0;

    const threshold = this.revenueThreshold ? this.revenueThreshold + 0.01 : 0;
    const percentage = (this.revenue * 100) / threshold;
    return Math.trunc(percentage * 100) / 100;
  }

  constructor(
    country: string,
    registered: boolean,
    revenueThreshold: number,
    revenue: number,
    currency: string,
    region?: string
  ) {
    this.country = country;
    this.registered = registered;
    this.revenueThreshold = revenueThreshold;
    this.revenue = revenue;
    this.currency = currency;

    this.region = region;
  }
}

import { useIntl } from "react-intl";

import { MonthlyAggregate } from "@/server/trpc/routers/tax/aggregates";
import { USThreshold, USThresholds } from "@/server/trpc/routers/tax/thresholds";

type EvaluationResult = [from: Date, to: Date];

export function useThresholdOps() {
  const intl = useIntl();

  function findThreshold(thresholds: USThresholds[], fips: string) {
    if (!thresholds) return;
    const timeline = thresholds.find((t) => t.fips === fips);
    if (!timeline || (timeline.thresholds?.length ?? 0) === 0) return;

    const now = new Date();
    timeline.thresholds.sort((a, b) => b.effectiveFrom.getTime() - a.effectiveFrom.getTime());
    const threshold = timeline.thresholds.find((t) => t.effectiveFrom.getTime() <= now.getTime());
    return threshold;
  }

  function getDescription(threshold?: USThreshold): string {
    if (!threshold) return "";
    const { revenueLimit, transactionLimit, clause } = threshold;
    if (!revenueLimit && !transactionLimit) {
      return intl.formatMessage({ id: "4Nnkm/", defaultMessage: "No threshold" });
    }

    if (clause && clause !== "AND" && clause !== "OR") {
      throw new Error("Invalid clause parameter");
    }

    const revenueDescription = revenueLimit
      ? intl.formatMessage(
          {
            id: "g7o86r",
            defaultMessage: "in excess of {revenue} revenue",
          },
          {
            revenue: intl.formatNumber(revenueLimit, {
              style: "currency",
              currency: "USD",
            }),
          }
        )
      : "";

    const transactionDescription = transactionLimit
      ? intl.formatMessage(
          {
            id: "w8fXyh",
            defaultMessage: "in excess of {transactions} transactions",
          },
          { transactions: intl.formatNumber(transactionLimit) }
        )
      : "";

    const clauseDescription =
      clause === "AND"
        ? intl.formatMessage({
            id: "3mvL2Q",
            defaultMessage: "and",
          })
        : intl.formatMessage({
            id: "Ntjkqd",
            defaultMessage: "or",
          });

    const thresholdDescription = [revenueDescription, transactionDescription]
      .filter(Boolean)
      .join(` ${clauseDescription} `);

    return thresholdDescription;
  }

  function getEvaluationPeriod(threshold: USThreshold, month: Date): EvaluationResult {
    const _year = month.getFullYear();
    const _month = month.getMonth() + 1;

    const monthThisYear = _month === 12;
    let nextMonth = _month + 1;
    if (nextMonth > 12) nextMonth %= 12;

    const startMonth = threshold.customStartMonth ?? 1;
    const customThisYear = startMonth <= _month;

    let nextQuarter = Math.ceil(_month / 3) + 1;
    if (nextQuarter > 4) nextQuarter %= 4;
    const quarterThisYear = nextQuarter === 1;
    switch (threshold.evaluationPeriod) {
      case "CALENDAR_YEAR":
        return [new Date(`${_year}-01-01`), new Date(`${_year + 1}-01-01`)];
      case "CUSTOM_MONTH_TO_PRESENT":
        return [
          new Date(`${customThisYear ? _year : _year - 1}-${startMonth}-01`),
          new Date(`${customThisYear ? _year + 1 : _year}-${startMonth}-01`),
        ];
      case "LAST_12_MONTHS":
        return [
          new Date(`${monthThisYear ? _year : _year - 1}-${pad(nextMonth)}-01`),
          new Date(`${monthThisYear ? _year + 1 : _year}-${pad(nextMonth)}-01`),
        ];
      case "LAST_4_QUARTERS":
        return [
          new Date(`${quarterThisYear ? _year : _year - 1}-${pad(nextQuarter)}-01`),
          new Date(`${quarterThisYear ? _year + 1 : _year}-${pad(nextQuarter)}-01`),
        ];
      default:
        // US does not use LAST_MONTH or LAST_3_MONTHS so throwing an error
        throw new Error(`Unknown evaluation period: ${threshold.evaluationPeriod}`);
    }
  }

  function getEvaluationDescription(threshold: USThreshold): string {
    const { evaluationPeriod, customStartMonth } = threshold;
    switch (evaluationPeriod) {
      case "CALENDAR_YEAR":
        return intl.formatMessage({ id: "dAe8qZ", defaultMessage: "Calendar year" });
      case "CUSTOM_MONTH_TO_PRESENT":
        if (customStartMonth) {
          const t = new Date(2023, customStartMonth - 1, 15);
          return intl.formatMessage(
            { id: "r4zyP5", defaultMessage: "From the last {month} to the present" },
            { month: t.toLocaleString("default", { month: "long" }) }
          );
        }
        break;
      case "LAST_12_MONTHS":
        return intl.formatMessage({ id: "r5sWuC", defaultMessage: "Last 12 months" });
      case "LAST_4_QUARTERS":
        return intl.formatMessage({ id: "uR3Qqr", defaultMessage: "Last 4 quarters" });
    }
    return intl.formatMessage({ id: "fnKnbC", defaultMessage: "No evaluation period" });
  }

  function groupByEvaluationPeriod(
    months: MonthlyAggregate<Date>[],
    threshold?: USThreshold,
    onDate = new Date()
  ): MonthlyAggregate<Date>[][] {
    if (!threshold) return [months];
    if (months.length === 0) return [months];
    const [start] = getEvaluationPeriod(threshold, onDate);
    const current = months.filter((m) => new Date(m.month).getTime() >= start.getTime());
    const previous = months.filter((m) => new Date(m.month).getTime() < start.getTime());
    start.setDate(start.getDate() - 1);
    return [current, ...groupByEvaluationPeriod(previous, threshold, start)];
  }

  function pad(month: number) {
    return `${month}`.padStart(2, "0");
  }

  return {
    findThreshold,
    getDescription,
    getEvaluationPeriod,
    getEvaluationDescription,
    groupByEvaluationPeriod,
  };
}
